@font-face {
	font-family: "Dharma Gothic E";
	src: url("./fonts/DharmaGothicE_Bold_R.otf") format(woff);
	font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
:root {
	--background-image-desktop: url(./pages/home/img/desktop.png) no-repeat center right / cover;
	--background-image-tab: url(./pages/home/img/tab.png) no-repeat top center / cover;
	--background-image-mobile: url(./pages/home/img/mobile.png) no-repeat top center / cover;
	--heading-fonts: "Dharma Gothic E";
	--body-fonts: "Roboto", sans-serif;
	--base: #009cb4;
	--body: #000000;
	--white: #ffffff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--heading-fonts);
	font-weight: 400;
}
body {
	font-family: var(--body-fonts);
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.5625px;
	color: var(--body);
	@media (min-width: 768px) {
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 0.5625px;
	}
}
@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}
ul {
	padding: 0;
	margin: 0;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.container,
.container-fluid {
	padding-left: 20px;
	padding-right: 20px;
}
@media (min-width: 768px) {
	.container,
	.container-fluid {
		padding-left: 40px;
		padding-right: 40px;
	}
}
/* Banner */
.banner-section {
	background: var(--background-image-mobile);
	clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 29px), 0% 100%);
	padding: 401px 0 65px;
	position: relative;
	z-index: 2;
	@include breakpoint(md) {
		background: var(--background-image-tab);
		clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 59px), 0% 100%);
		padding: 269px 0;
	}
	@include breakpoint(lg) {
		background: var(--background-image-desktop);
		clip-path: polygon(0 0, 100% 0%, 100% calc(100% - 54px), 0% 100%);
		padding: 226px 0 225px;
	}
	&::before {
		background: linear-gradient(
			360deg,
			rgba(0, 0, 0, 0.8) 54.17%,
			rgba(0, 0, 0, 0) 100%
		);
		inset: 0;
		position: absolute;
		content: "";
		@include breakpoint(lg) {
			background: linear-gradient(
				90deg,
				rgba(0, 0, 0, 0.8) 54.17%,
				rgba(0, 0, 0, 0) 100%
			);
		}
	}
	.container {
		@include breakpoint(md) {
			@include breakpoint(max-xl) {
				max-width: 100%;
			}
		}
	}
}
.text-base {
	color: var(--base);
}
.banner-content {
	position: relative;
	z-index: 1;
	color: var(--white);
	.title {
		font-size: 60px;
		line-height: 56px;
		margin-bottom: 16px;
		max-width: 340px;
	}
	@include breakpoint(sm) {
		.title {
			max-width: 450px;
		}
	}
	@include breakpoint(md) {
		max-width: 505px;
		.title {
			font-size: 90px;
			line-height: 85px;
			max-width: 100%;
		}
	}
	@include breakpoint(xl) {
		max-width: 670px;
		.title {
			font-size: 120px;
			line-height: 112px;
		}
	}
}
.__download-btn {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	position: fixed;
	right: 20px;
	display: flex;
	align-items: center;
	width: 242px;
	padding: 13px 20px;
	bottom: 20px;
	z-index: 10;
	.subtitle {
		font-size: 40px;
		line-height: 38px;
		margin: 0;
	}
	img {
		width: 78px;
		height: 78px;
	}
}
.__banner-btn {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	max-width: 350px;
	margin-top: 24px;
	a {
		display: block;
		width: 100px;
		flex-grow: 1;
		img {
			height: 50px;
		}
	}
}
/*Language selector*/
#language-selector {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 9999;
	font-size: 14px;
}
/*Header*/
header {
	position: absolute;
	top: 40px;
	left: 0;
	z-index: 9999;
	width: 100%;
}
.menu {
	display: flex;
	flex-wrap: wrap;
	column-gap: 32px;
	row-gap: 5px;
	li {
		a {
			text-decoration: none;
			color: var(--white);
			&:not(:hover) {
				opacity: 0.6;
			}
			&.active {
				opacity: 1;
			}
		}
	}
}
.erfenhet-section {
	position: relative;
	z-index: 2;
	&::before {
		background: linear-gradient(
			360deg,
			rgba(0, 0, 0, 0.8) 54.17%,
			rgba(0, 0, 0, 0) 100%
		);
		inset: 0;
		position: absolute;
		content: "";
		@include breakpoint(lg) {
			background: linear-gradient(
				90deg,
				rgba(0, 0, 0, 0.8) 54.17%,
				rgba(0, 0, 0, 0) 100%
			);
		}
	}
	clip-path: polygon(0 29px, 100% 0%, 100% calc(100% - 29px), 0% 100%);
	position: relative;
	@include breakpoint(md) {
		clip-path: polygon(0 59px, 100% 0%, 100% calc(100% - 59px), 0% 100%);
	}
	@include breakpoint(lg) {
		clip-path: polygon(0 54px, 100% 0%, 100% calc(100% - 54px), 0% 100%);
	}
	min-height: 100vh;
	padding: 100px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	color: var(--white);
	justify-content: flex-end;
	@include breakpoint(md) {
		padding: 200px 0;
		justify-content: center;
	}
}
.section-content {
	position: relative;
	z-index: 1;
	.title {
		font-size: 60px;
		line-height: 56px;
		margin-bottom: 16px;
		max-width: 340px;
	}
	@include breakpoint(sm) {
		.title {
			max-width: 450px;
		}
	}
	@include breakpoint(md) {
		max-width: 505px;
		.title {
			font-size: 90px;
			line-height: 85px;
			max-width: 100%;
		}
	}
	@include breakpoint(xl) {
		max-width: 670px;
		.title {
			font-size: 120px;
			line-height: 112px;
		}
		p {
			max-width: 555px;
		}
	}
}
/* Faqs Section */
.faqs-section {
	background: #2d2d2d;
	padding: 100px 0;
}
.faq-wrapper {
	color: var(--white);
	padding-left: 20px;
	padding-right: 20px;
	margin: 0 auto;
	.main-item {
		padding-bottom: 32px;
		margin-bottom: 80px;
		letter-spacing: 0.499858px;
		border-bottom: 1px solid rgba(#ffffff, 0.6);
		.title {
			font-size: 60px;
			line-height: 56px;
			margin-bottom: 16px;
			padding-bottom: 16px;
			border-bottom: 1px solid rgba(#ffffff, 0.6);
		}
	}
	@include breakpoint(md) {
		max-width: 882px;
	}
	@include breakpoint(lg) {
		max-width: 710px;
	}
	.faq {
		&:not(:last-child) {
			margin-bottom: 36px;
		}
		.subtitle {
			font-size: 16px;
			line-height: 1.5;
			font-family: var(--body);
			font-weight: 500;
		}
	}
}
.vetenskapen-section {
	padding: 100px 0;
	@include breakpoint(lg) {
		padding: 139px 0;
	}
}
.vetenskapen-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	max-width: 1132px;
	margin: 0 auto;
	.section-content {
		max-width: 100%;
		width: 100%;
		.title {
			max-width: 100%;
		}
	}
	@include breakpoint(md) {
		gap: 56px;
		padding-left: 20px;
		padding-right: 20px;
		.section-content {
			max-width: 100%;
			.title {
				max-width: 100%;
			}
			.txt {
				max-width: 625px;
			}
		}
	}
	@include breakpoint(lg) {
		gap: 67px;
		flex-wrap: nowrap;
		.section-content {
			width: 625px;
		}
	}
}
.ventenskapen-img {
	width: 100%;
	max-width: 440px;
	img {
		width: 100%;
		border-radius: 20px;
	}
}
.app-section {
	background: black;
	clip-path: polygon(0 29px, 100% 0%, 100% 100%, 0% 100%);
	position: relative;
	@include breakpoint(md) {
		clip-path: polygon(0 59px, 100% 0%, 100% 100%, 0% 100%);
	}
	@include breakpoint(lg) {
		clip-path: polygon(0 54px, 100% 0%, 100% 100%, 0% 100%);
	}
	padding: 100px 0 0;
	@include breakpoint(md) {
		padding: 120px 0;
	}
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.app-wrapper {
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 64px;
	@include breakpoint(lg) {
		gap: 102px;
		.app-cont {
			max-width: 440px;
			text-align: center;
		}
	}
}
.app-cont {
	text-align: center;
	color: var(--white);
	.title {
		font-size: 60px;
		line-height: 56px;
		margin-bottom: 16px;
	}
	@include breakpoint(md) {
		.title {
			font-size: 90px;
			line-height: 85px;
		}
		@include breakpoint(max-lg) {
			max-width: 496px;
		}
	}
	@include breakpoint(lg) {
		text-align: left;
		.title {
			font-size: 120px;
			line-height: 112px;
		}
	}
	@include breakpoint(xl) {
		.title {
			font-size: 120px;
			line-height: 112px;
		}
	}
}
.app-img {
	max-width: 305px;
	max-width: 100%;
	@include breakpoint(max-md) {
		margin-bottom: -130px;
	}
}
.__client-item {
	text-align: center;
	blockquote {
		font-weight: 400;
		font-size: 34px;
		line-height: 32px;
		margin: 0;
		font-family: var(--heading-fonts);
	}
	.subtitle {
		font-family: var(--body-fonts);
		margin-top: 24px;
	}
	.__client-item-img {
		width: 180px;
		aspect-ratio: 1;
		margin: 0 auto 40px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	@include breakpoint(md) {
		blockquote {
			font-size: 50px;
			line-height: 46px;
		}
	}
}
.testimonial-slider {
	max-width: 754px;
	margin: 0 auto;
	@include breakpoint(lg) {
		max-width: 1000px;
	}
}
.testimonial-section {
	padding: 129px 0;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	@include breakpoint(lg) {
		padding: 180px 0;
	}
	.slick-list {
		max-width: 900px;
		margin: 0 auto;
	}
	.slick-arrow {
		opacity: 0.3;
		transition: all ease 0.3s;
		&:hover {
			opacity: 1;
		}
	}
}
.slick-dots {
	margin-top: 14px;
	display: block;
	position: relative;
	li {
		margin: 2px 6px;
		height: 2px;
		width: 24px;
		button {
			padding: 0;
			height: 2px;
			width: 24px;
			&::before {
				display: none;
			}
			background: rgba(#009cb4, 0.3);
		}
		&.slick-active {
			button {
				background: #009cb4;
			}
		}
	}
}
/* About Section */
.about-section {
	padding: 70px 0 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	min-height: 100vh;
	margin-top: -59px;
	margin-bottom: -59px;
	position: relative;
	.section-content {
		padding-top: 64px;
		max-width: 496px;
		margin: 0 auto;
	}
	@include breakpoint(lg) {
		padding: 0;
		.section-content {
			padding: 100px 0;
			max-width: 440px;
			margin-left: 0;
		}
	}
	.section-content {
		@include breakpoint(max-md) {
			.title {
				max-width: 100%;
			}
		}
		@include breakpoint(max-lg) {
			padding-top: 64px;
			background: #ffffff;
			box-shadow: 0px -20px 50px rgba(0, 0, 0, 0.15);
		}
	}
	@include breakpoint(max-lg) {
		.container {
			max-width: 100%;
			padding: 0;
			.row {
				margin: 0 !important;
				> div {
					padding: 0 !important;
				}
			}
		}
		.section-content {
			padding-top: 64px;
			background: #ffffff;
			box-shadow: 0px -30px 30px rgba(0, 0, 0, 0.1);
			max-width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			.cont {
				max-width: 496px;
				margin: 0 auto;
			}
		}
		.__about-img {
			img {
				max-width: 285px;
			}
		}
	}
}
@include breakpoint(lg) {
	.__about-img {
		position: relative;
		img {
			max-width: 100%;
			max-width: 285px;
		}
	}
}
.cursor-pointer {
	cursor: pointer;
}
.__modal {
	position: fixed;
	// background: rgba(#000, 0.7);
	inset: 0;
	z-index: 9999;
	transition: all ease 0.6s;
	display: flex;
	justify-content: center;
	align-items: center;
	.cancel {
		right: 20px;
		top: 20px;
		position: absolute;
		width: 35px;
		cursor: pointer;
		z-index: 10;
	}
	.__modal-body-close {
		inset: 0;
		position: absolute;
		z-index: 3;
		background: rgba(#000, 0.7);
		cursor: zoom-out;
	}
	&-body {
		position: relative;
		z-index: 9;
		animation: zoomIn 1s;
		-webkit-animation: zoomIn 1s;
		-moz-animation: zoomIn 1s;
		max-width: 800px;
		height: 480px;
		width: 100% !important;
		padding-left: 15px;
		padding-right: 15px;
		* {
			max-width: 800px !important;
			width: 100% !important;
		}
		iframe {
			max-width: 800px;
			height: 480px;
		}
		mux-player {
			max-width: 800px;
			height: 480px;
		}
		@include breakpoint(md) {
			width: 100%;
			max-width: 800px;
			height: 480px;
			* {
				max-width: 800px !important;
				width: 100% !important;
			}
			iframe {
				max-width: 800px;
				height: 480px;
			}
		}
	}
}
@keyframes zoomIn {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
.phone-1 {
	position: absolute;
	width: 285px;
	top: calc(100% - 100px);
	right: calc(50% + 10px);
}
.phone-2 {
	position: absolute;
	width: 285px;
	top: 50%;
	transform: translateY(-50%);
	right: calc(50% + 380px);
}
